.footer-links h3{
  color: #ff4a57;
  padding-bottom: 10px;
}

.footer-links li{
  display: flex;
  padding: 0;
}

.footer-links li a{
  color: #ccc;
}


.footer-icons h3{
  color: #ff4a57;
  padding-left: 5px;
  padding-bottom: 10px;
}

.footer-links li a:hover{
  color: #ff4a57;
  text-decoration: none;
}

.footer-icons ul{
  padding-inline-start: 0;
  list-style-type: none;
}
.footer-icons li {
  display: inline;
  flex-flow: column;
}

.footer-icons .btn{
  border: none;
  background: #1F2235;
}

.footer-brand h3{
  font-weight: normal;
  color: #ff4a57;
}