.go-to-top{
    position: fixed;
    bottom: 40px;
    right: 40px;
}

.hide{
    opacity: 0;
    transition: opacity 1s ease-out;
}

.show{
    display: block;
    opacity: 1;
    transition: opacity 1s ease-in;
}

.go-to-top-icon{
    font-size: 30px!important;
    transform: rotate(-45deg);
    color: #ff4a57;
}


.go-to-top-icon:hover{
    cursor: pointer;
}